import { useCart } from "@/lib/providers/cart-provider";
import { cn } from "@/lib/utils";
import { getSession } from "next-auth/react";
import Link from "next/link";
import React, { useEffect } from "react";

const ButtonCart = ({ className }: { className?: string }) => {
    const { getCart, cart, numItemsInCart } = useCart();

    useEffect(() => {
        const _getCart = async () => {
            const session = await getSession();
            if (session && session.user) {
                getCart();
            }
        };
        _getCart();
    }, []);

    const numItems = numItemsInCart();

    return (
        <div className={cn("mx-[10px] relative flex-shrink-0", className)}>
            <Link href={"/cart"} className="text-gray">
                <img src={"/assets/icons/shoppingbasket.svg"} alt="Button Image" className="h-[22px] w-[22px]" />
                {numItems > 0 && (
                    <div className="bg-c2 w-5 h-5 py-0.5 px-0.5 rounded-full absolute -right-1 -top-1.5">
                        <div className="flex justify-center w-full items-center overflow-clip">
                            <p className="text-white text-xs">{numItems}</p>
                        </div>
                    </div>
                )}
            </Link>
        </div>
    );
};

export const ButtonCartMobile = ({ className }: { className?: string }) => {
    const { getCart, cart, numItemsInCart } = useCart();

    useEffect(() => {
        const _getCart = async () => {
            const session = await getSession();
            if (session && session.user) {
                getCart();
            }
        };
        _getCart();
    }, []);

    const numItems = numItemsInCart();

    return (
        <div className={cn("mx-[10px] relative flex-shrink-0", className)}>
            <img src={"/assets/icons/shoppingbasket.svg"} alt="Button Image" className="h-[22px] w-[22px]" />
            {numItems > 0 && (
                <div className="bg-c2 w-5 h-5 py-0.5 px-0.5 rounded-full absolute -right-1 -top-1.5">
                    <div className="flex justify-center w-full items-center overflow-clip">
                        <p className="text-white text-xs">{numItems}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ButtonCart;
