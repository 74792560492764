"use client";

import { cn } from "@/lib/utils";
import Link from "next/link";
import { useCallback, useEffect, useRef, useState } from "react";
import Image from "next/image";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { getUser } from "@/actions";
import { IUserNotification } from "@/util/query/notification-query";
import { DateToFormatWithTimeZone } from "@/util/date-format";
import { useTranslation } from "@/app/i18n/client";
import { getLinkTarget, truncateString } from "@/util/util";
import { uniqBy } from "lodash";

const ButtonNotification = () => {
    const { loc } = useTranslation();

    const scrollContentRef = useRef<HTMLDivElement>(null);

    const [isShowDot, setIsShowDot] = useState<boolean>(false);
    const [notifications, setNotifications] = useState<IUserNotification[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [isLatest, setIsLatest] = useState<boolean>(false);
    const [onInit, setOnInit] = useState<boolean>(false);
    const [latestDate, setLatestDate] = useState<string>("2010-01-01T00:00:00.000Z");
    const [isCheckLoading, setIsCheckLoading] = useState<boolean>(false);

    useEffect(() => {
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    useEffect(() => {
        let intervalId: NodeJS.Timeout | number | null = null;
        if (onInit && !isLoading) {
            // Check New Notfication
            intervalId = setInterval(() => {
                if (!isCheckLoading) {
                    fetchCheckData(latestDate);
                }
            }, 10000);
        }

        return () => {
            // Clean up
            if (intervalId !== null) {
                clearInterval(intervalId);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onInit, isLoading, isCheckLoading, latestDate]);

    const fetchData = async () => {
        const user = await getUser();

        if (!user) {
            return;
        }

        setIsLoading(true);

        try {
            const response = await fetch(`/api/notification?locale=${loc}&page=${page}`, {
                method: "GET",
            });
            const data = (await response.json()) as IUserNotification[];

            if (data.length > 0) {
                setNotifications((prevItems) => uniqBy([...prevItems, ...data], "id"));

                const isNew = data.find((f) => !f.readStatus);

                if (isNew) {
                    setIsShowDot(true);
                }

                const latestTime = data[0].createdAt as unknown as string;

                if (page === 1) {
                    setLatestDate(latestTime as unknown as string);
                }
            } else {
                setIsLatest(true);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);

            if (!onInit) {
                setOnInit(true);
            }
        }
    };

    const fetchCheckData = async (latest: string) => {
        const user = await getUser();

        if (!user) {
            return;
        }

        setIsCheckLoading(true);

        try {
            const response = await fetch(`/api/notification/check?locale=${loc}&latestDate=${latest}`, {
                method: "GET",
            });
            const data = (await response.json()) as IUserNotification[];

            if (data.length > 0) {
                setNotifications((prevItems) => uniqBy([...data, ...prevItems], "id"));

                const isNew = data.find((f) => !f.readStatus);

                if (isNew) {
                    setIsShowDot(true);
                }

                const latestTime = data[0].createdAt as unknown as string;
                setLatestDate(latestTime);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsCheckLoading(false);
        }
    };

    const onScroll = useCallback(() => {
        if (!isLoading && !isLatest && scrollContentRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollContentRef.current;
            const scrollThreshold = (clientHeight + scrollTop) / scrollHeight;
            if (scrollThreshold >= 0.8) {
                setIsLoading(true);
                setPage((prevPage) => prevPage + 1);
            }
        }
    }, [scrollContentRef, isLoading, isLatest]);

    const onOpenChange = async (open: boolean): Promise<void> => {
        if (open) {
            try {
                await fetch(`/api/notification/markread`, {
                    method: "POST",
                });
                setIsShowDot(false);
                setNotifications((prevItems) =>
                    uniqBy(
                        [
                            ...prevItems.map((m) => {
                                return {
                                    ...m,
                                    readStatus: true,
                                };
                            }),
                        ],
                        "id",
                    ),
                );
            } catch (error) {
                console.error(error);
            }
        }
    };

    return (
        <div className={cn("mx-[10px] relative flex-shrink-0")}>
            <Popover onOpenChange={onOpenChange}>
                <PopoverTrigger className="relative align-middle">
                    <Image
                        src={"/assets/icons/bell.svg"}
                        alt="Bell"
                        width={22}
                        height={22}
                        unoptimized
                        className="align-middle"
                    />
                    {isShowDot && <div className="bg-error w-[8px] h-[8px] rounded-full absolute top-0 right-0"></div>}
                </PopoverTrigger>
                <PopoverContent
                    ref={scrollContentRef}
                    className="bg-white py-5 rounded-lg z-9999 w-[360px] max-h-96 overflow-y-auto"
                    onScroll={onScroll}>
                    <div className="flex flex-col gap-2">
                        {/* Header */}
                        <div className="flex flex-row justify-between items-center px-5">
                            <div className="font-normal text-xl">แจ้งเตือน</div>
                            {/* <Link href={"/notifications"} className="font-medium text-base text-c2">
                                ดูทั้งหมด
                            </Link> */}
                        </div>

                        {/* Card Content */}
                        <div className="flex flex-col items-center">
                            {notifications.map((notification) => {
                                return (
                                    <Link
                                        key={notification.id}
                                        href={notification.link || ""}
                                        target={getLinkTarget(notification.linkTarget || "SELF")}
                                        className="w-full flex flex-row gap-4 border-b px-5 py-3 cursor-pointer hover:bg-black hover:bg-opacity-5">
                                        {/* ICON */}
                                        <Image
                                            src={notification.notificationBody.icon || "/assets/icons/bell.svg"}
                                            alt="Notification Image"
                                            width={22}
                                            height={22}
                                            className="h-[22px] w-[22px]"
                                            unoptimized
                                        />

                                        {/* DESCCRIPTION */}
                                        <div className="flex flex-col gap-3 flex-1">
                                            <div className="font-normal text-base flex flex-col gap-[1px]">
                                                <span className="break-all">
                                                    {truncateString(notification.notificationBody?.title || "", 1024)}
                                                </span>
                                                <span className="break-all">
                                                    {truncateString(
                                                        notification.notificationBody?.description || "",
                                                        1024,
                                                    )}
                                                </span>
                                            </div>
                                            <div className="font-light text-sm text-c3">
                                                {DateToFormatWithTimeZone(
                                                    new Date(notification.publishAt),
                                                    loc,
                                                    "d MMMM yyyy HH:mm",
                                                )}
                                            </div>
                                        </div>

                                        {/* STATUS */}
                                        <div className="self-center">
                                            <div
                                                className={cn(
                                                    "rounded-full w-[8px] h-[8px]",
                                                    notification.readStatus ? "" : "bg-coupon-foreground",
                                                )}></div>
                                        </div>
                                    </Link>
                                );
                            })}

                            {isLoading && <p>Loading...</p>}
                        </div>
                    </div>
                </PopoverContent>
            </Popover>
        </div>
    );
};

export default ButtonNotification;
