"use client";

import { getLinkTarget } from "@/util/util";
import { usePathname, useSearchParams, useRouter } from "next/navigation";
import { Button, buttonVariants } from "./ui/button";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

import { useTranslation } from "@/app/i18n/client";
import Image from "next/image";
import { ChevronLeft, ChevronRight, MenuIcon, Search } from "lucide-react";
import { cn } from "@/lib/utils";
import {
    NavigationMenu,
    NavigationMenuList,
    NavigationMenuItem,
    NavigationMenuTrigger,
    NavigationMenuContent,
    NavigationMenuLink,
} from "./ui/navigation-menu";

import Link from "next/link";
import { ProfileMenu, ShoppingCatagory } from "./nav-ulife-server-component";
import { cva } from "class-variance-authority";
import * as actions from "@/actions";
import { Sheet, SheetClose, SheetContent, SheetOverlay, SheetTrigger } from "./ui/sheet";
import { useEffect, useState } from "react";
import ButtonCart, { ButtonCartMobile } from "./button-cart";
import { Input } from "./ui/input";

import { setCookie } from "cookies-next";
import { cookieName } from "@/app/i18n/settings";
import { CUSTOMER_LEVEL } from "@prisma/client";
import { useSystemConfig } from "@/lib/providers/system-config-provider";
import { getUser } from "@/actions/user";
import ButtonProfile from "./button-profile";
import ButtonNotification from "./notification/button-notification";

export const GredientBannerBar = (strText: string) => {
    return (
        <div className="btn_purple_gredient w-full h-[37px] font-kanit  flex justify-center items-center text-sm text-white">
            {strText}
        </div>
    );
};

export const YouLifeBusineseButton = ({ strText, href }: { strText: string; href: string }) => {
    const handleClick = () => {
        console.log("Round gradient button clicked!");
        // Add your custom logic here
    };

    return (
        <Link
            href={href}
            className={cn(buttonVariants({ variant: "gradient1" }), "w-[170px] h-full rounded-l-full rounded-r-none")}>
            <div className="text-white flex gap-2 justify-center items-center w-full pl-4">
                <h6 className="font-medium text-xs">{strText}</h6>
                <ChevronRight className="w-5 h-5" />
            </div>
        </Link>
    );
};

export const NavSearch = ({ clasName, onEnter }: { clasName?: string; onEnter?: () => void }) => {
    const searchparam = useSearchParams();
    const [searchInput, setSearchInput] = useState(searchparam.get("search") ?? "");
    const { t } = useTranslation();
    const rout = useRouter();

    const pathname = usePathname();
    const handleSearchInputChange = (e: any) => {
        setSearchInput(e.target.value);
    };

    const handleSearchEnter = (e: any) => {
        const kb = e as KeyboardEvent;
        if (kb.key.toLowerCase() == "enter") {
            e.preventDefault();
            if (searchInput != "") {
                rout.push("/search?search=" + searchInput);
                if (onEnter) {
                    onEnter();
                }
            }
        }
    };

    useEffect(() => {
        setSearchInput(searchparam.get("search") ?? "");
    }, [pathname, searchparam]);

    return (
        <div className={cn("flex items-center max-w-[280px] border rounded-full px-4 gap-1 ", clasName)}>
            <Link href={searchInput != "" ? "/search?search=" + searchInput : ""}>
                <Search className="w-4 h-4"></Search>
            </Link>

            <Input
                className="w-full text-sm font-light border-none h-full focus-visible:ring-0 focus-visible:ring-offset-0"
                type="text"
                id="searchInput"
                value={searchInput}
                onChange={handleSearchInputChange}
                onKeyDown={handleSearchEnter}
                placeholder={t("navigation.search")}
            />
        </div>
    );
};

export const MenuButton = ({ strText, idxKey }: { strText: string; idxKey: number }) => {
    const handleClick = () => {
        // Add your custom logic here
    };

    return (
        <div key={idxKey} className="m-2">
            <button onClick={handleClick} className="text-gray font-kanit">
                {strText}
            </button>
        </div>
    );
};

export const MenuButtonGredient = ({ className, strText }: { className: string; strText: string }) => {
    const handleClick = () => {
        // Add your custom logic here
    };

    return (
        <Button className={cn("w-[260px] text-sm", className)} variant="gradient4">
            {strText}
        </Button>
    );
};

export const NaviIcon = ({ nType }: { nType: number }) => {
    const iconImage: string[] = ["profile.svg", "heart.svg", "bell.svg", "shoppingbasket.svg"];
    const hrefs: string[] = ["/signin", "/profile/favorites", "", "/cart"];

    if (nType === 0) {
        return <ButtonProfile />;
    } else if (nType === 2) {
        return <ButtonNotification />;
    } else if (nType === 3) {
        return <ButtonCart />;
    } else {
        return (
            <div className="mx-[10px] flex-shrink-0">
                <div className="text-gray ">
                    <Link href={hrefs[nType]}>
                        <Image
                            src={`/assets/icons/${iconImage[nType]}`}
                            alt="Button Image"
                            width={22}
                            height={22}
                            className="h-[22px] w-[22px]"
                            unoptimized
                        />
                    </Link>
                </div>
            </div>
        );
    }
};

const navigationMenuTriggerStyleK = cva(
    "group inline-flex h-10 w-max items-center justify-center rounded-md bg-background px-4 py-2 text-sm font-medium transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[active]:bg-accent/50 data-[state=open]:bg-accent/50",
);

export const ShoppingTopBarMenu = ({ itmMainCats }: { itmMainCats: ShoppingCatagory[] }) => {
    return (
        <NavigationMenu className="">
            <NavigationMenuList>
                {itmMainCats.map((itm: ShoppingCatagory, idx: number) => (
                    <div key={idx}>
                        {itm.subcat.length > 0 ? (
                            <NavigationMenuItem>
                                <NavigationMenuTrigger className="mt-1 bg-transparent p-0 font-light text-sm hover:bg-transparent focus:bg-transparent data-[active]:bg-transparent data-[state=open]:bg-transparent">
                                    <Link
                                        href={itm.link}
                                        legacyBehavior
                                        passHref
                                        target={getLinkTarget(itm.linkTarget || "SELF")}>
                                        <NavigationMenuLink className="font-light text-sm p-2 text-c4 hover:text-c2 focus:text-c2 focus:outline-none data-[active]:bg-transparent data-[state=open]:bg-transparent whitespace-nowrap">
                                            {itm.name}
                                        </NavigationMenuLink>
                                    </Link>
                                </NavigationMenuTrigger>
                                <NavigationMenuContent>
                                    <ShoppingTopBarSubMenuContent catData={itm} />
                                </NavigationMenuContent>
                            </NavigationMenuItem>
                        ) : (
                            <Link
                                href={itm.link}
                                legacyBehavior
                                passHref
                                target={getLinkTarget(itm.linkTarget || "SELF")}>
                                <NavigationMenuLink className="font-light text-sm text-c4 p-2 h-10 hover:text-c2  focus:text-c2 focus:outline-none data-[active]:bg-transparent data-[state=open]:bg-transparent whitespace-nowrap">
                                    {itm.name}
                                </NavigationMenuLink>
                            </Link>
                        )}
                    </div>
                ))}
            </NavigationMenuList>
        </NavigationMenu>
    );
};

export const ShoppingTopBarSubMenuContent = ({ catData }: { catData: ShoppingCatagory }) => {
    console.log(JSON.stringify(catData));

    const [hoveredIndex, setHoveredIndex] = useState<number | -1>(-1);

    const handleMouseEnter = (index: number) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(-1);
    };

    return (
        <div className="w-[900px] h-auto">
            <div className="flex m-5 gap-5">
                <div className="flex flex-col gap-2">
                    <p className="font-light text-xs text-grayc3 hover:text-c2">{catData.name}</p>

                    <div className="flex flex-col gap-2">
                        {catData.subcat?.map((itm: ShoppingCatagory, idx: number) => (
                            <Link
                                key={idx}
                                className={`text-c4 ${idx == hoveredIndex ? "font-medium" : "font-light"}`}
                                href={itm.link}
                                onMouseEnter={() => handleMouseEnter(idx)}>
                                {itm.head}
                            </Link>
                        ))}
                    </div>
                </div>

                {hoveredIndex > -1 && <div className="h-auto mx-5  w-2 border-l-2 border-gray-100"></div>}

                {hoveredIndex > -1 && (
                    <div className="flex flex-col gap-2">
                        <p className="font-light text-xs text-cyan-400 hover:text-c2">
                            {catData.subcat[hoveredIndex].head}
                        </p>
                        {catData.subcat[hoveredIndex].subcat?.map((itm: ShoppingCatagory, idx: number) => (
                            <Link key={idx} href={itm.link} className="text-c4 hover:font-medium font-light">
                                {itm.name}
                            </Link>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export const LanguageSelectionNav = () => {
    const pathName = usePathname();
    const router = useRouter();
    const { lang } = useTranslation();

    const handleOnValueChanged = (value: string) => {
        setCookie(cookieName, value);
        router.push(pathName);
        // router.refresh();
        window.location.reload();
    };

    return (
        <Select value={lang} onValueChange={handleOnValueChanged}>
            <SelectTrigger className="w-32 h-[20px] bg-transparent border-collapse selection:bg-transparent hover:bg-transparent border-white">
                <SelectValue placeholder="Select Language" />
            </SelectTrigger>
            <SelectContent className="z-9999">
                <SelectGroup>
                    <SelectItem value="en" className="text-xs">
                        English
                    </SelectItem>
                    <SelectItem value="th" className="text-xs">
                        ภาษาไทย
                    </SelectItem>
                </SelectGroup>
            </SelectContent>
        </Select>
    );
};

export const ProfileMenuButton = ({
    profile,
    idxKey,
    login,
}: {
    profile: ProfileMenu;
    idxKey: number;
    login: boolean;
}) => {
    const [imgUrl, setImgUrl] = useState<string | null>();

    useEffect(() => {
        const GetUserImage = async () => {
            const r = await getUser();
            const imageUse = r?.image;
            setImgUrl(imageUse);
        };

        if (profile.id === "0") GetUserImage();
    }, [profile]);

    return (
        <div key={idxKey} className={"flex items-center gap-2 -ml-2   "}>
            <div className="w-1/6 flex justify-center  ">
                {profile.id == "2" ? (
                    <SheetClose asChild>
                        <Link href={profile.link}>
                            <ButtonCartMobile className="m-0 p-0 w-5 " />
                        </Link>
                    </SheetClose>
                ) : (
                    <SheetClose asChild>
                        <Link href={profile.link}>
                            <Image
                                src={imgUrl || `/assets/icons/${profile.icon}`}
                                alt={""}
                                width={20}
                                height={20}
                                className={cn(
                                    profile.id == "0" ? "rounded-full" : "",
                                    profile.id == "0" && imgUrl ? "w-7 h-7" : "w-5 h-5",
                                )}
                                unoptimized
                            />
                        </Link>
                    </SheetClose>
                )}
            </div>
            <div className="w-auto">
                {profile.id == "0" ? (
                    <ButtonLogInMobile login={login} />
                ) : (
                    <SheetClose asChild>
                        <Link href={profile.link}>
                            <p className={" text-c4 hover:text-c2 text-xl font-light hover:font-normal "}>
                                {profile.name}
                            </p>
                        </Link>
                    </SheetClose>
                )}
            </div>
        </div>
    );
};

export const ButtonLogInMobile = ({ login }: { login: boolean }) => {
    const { t } = useTranslation();

    return (
        <div>
            {login ? (
                <SheetClose asChild>
                    <button onClick={() => actions.signOut()}>
                        <p className="text-c4 hover:text-c2 text-xl font-light hover:font-norma;">
                            {t("navigation.logout")}
                        </p>
                    </button>
                </SheetClose>
            ) : (
                <SheetClose asChild>
                    <Link href="/signin">
                        <p className="text-c4 hover:text-c2 text-xl font-light hover:font-normal">
                            {t("navigation.login")}
                        </p>
                    </Link>
                </SheetClose>
            )}
        </div>
    );
};

export const NavLogInClient = ({
    shopcats,
    login,
    template,
    level,
}: {
    shopcats: ShoppingCatagory[];
    login: boolean;
    template: string;
    level: CUSTOMER_LEVEL;
}) => {
    const router = useRouter();
    const { t } = useTranslation();
    const { canPurchase } = useSystemConfig();

    let type = 1;
    if (template == "TEMPLATE_1") {
        type = 0;
    }

    const additionClassname = ["", "bg-c1"];

    const pathName = usePathname();

    const handleOnValueChanged = (value: string) => {
        setCookie(cookieName, value);
        router.push(pathName);
        router.refresh();
    };
    const mainProfileMenu: ProfileMenu[] = [
        { id: "0", name: t("navigation.login"), link: "", icon: "profile.svg", active: true },
        {
            id: "1",
            name: t("navigation.my_favorite"),
            link: "/profile/favorites",
            icon: "heart.svg",
            active: login && level !== "GUEST" && level !== "NORMAL",
        },
        { id: "2", name: t("cart.cart_title"), link: "/cart", icon: "shoppingbasket.svg", active: canPurchase },
        { id: "3", name: t("navigation.contact"), link: "/business/contact-us", icon: "Contact.svg", active: true },
    ];

    const [open, setOpen] = useState(false);

    const [closeAll, setCloseAll] = useState(false);
    const pathname = usePathname();

    useEffect(() => {
        setCloseAll(false);
        setOpen(false);
    }, [pathname]);

    const ShoppingTopBarSubmenuMobile = ({
        itm,
        previous_text,
        bgcolor,
    }: {
        itm: ShoppingCatagory;
        previous_text: string;
        bgcolor?: string;
    }) => {
        return itm.subcat.length > 0 ? (
            <Sheet>
                <SheetTrigger className="w-full ">
                    <div className="flex justify-between items-center hover:text-c2 over:font-light">
                        {/* <p className="text-c4">{itm.name}</p> */}
                        <p className="w-5/6 text-c4 text-start hover:text-c2 text-xl font-light hover:font-light   ">
                            {itm.head}
                        </p>

                        <div className="flex justify-end w-1/6 ">
                            <ChevronRight className=" " />
                        </div>
                    </div>
                </SheetTrigger>
                <SheetContent
                    className={cn("w-[320px] p-0  overflow-auto", bgcolor)}
                    onAnimationEndCapture={(x) => {
                        console.log("onAnimationEndCapture ", x.animationName);
                        if (x.animationName == "exit") {
                            if (closeAll) {
                                setOpen(false);
                                console.log("shett close ", itm.head);
                            }
                        }
                    }}>
                    <div className="p-5">
                        <SheetClose asChild>
                            <Link href={""}>
                                <div className="flex -ml-3  w-auto mr-10">
                                    <ChevronLeft /> <p className="text-lg text-c4 font-normal">{previous_text}</p>
                                </div>
                            </Link>
                        </SheetClose>
                        <div className="mt-7 ">
                            <Link href={itm.link} target={getLinkTarget(itm.linkTarget || "SELF")}>
                                <h2 className="font-medium text-xl hover:text-c2">{itm.head}</h2>
                            </Link>

                            {itm.subcat[0].mode == "ICON" ? (
                                <div className="grid grid-cols-2  mt-8 items-center gap-3 w-full">
                                    {itm.subcat.map((x: ShoppingCatagory, idx: number) => {
                                        return x.subcat.length > 0 ? (
                                            <Sheet key={idx}>
                                                <SheetTrigger className="w-full ">
                                                    <div className="flex flex-col w-full h-20  justify-center items-center aspect-square">
                                                        <Image
                                                            src={x.icon}
                                                            alt={"image"}
                                                            width={130}
                                                            height={80}
                                                            unoptimized></Image>

                                                        <p className="text-c4 font-light text-sm mt-1">{x.name}</p>
                                                    </div>
                                                </SheetTrigger>
                                                <SheetContent
                                                    className={cn("w-[320px] p-0  overflow-auto", bgcolor)}
                                                    onAnimationEndCapture={(x) => {
                                                        console.log("onAnimationEndCapture ", x.animationName);
                                                        if (x.animationName == "exit") {
                                                            if (closeAll) {
                                                                setOpen(false);
                                                                console.log("shett close ", itm.head);
                                                            }
                                                        }
                                                    }}>
                                                    <div className="p-5">
                                                        <SheetClose asChild>
                                                            <Link href={""}>
                                                                <div className="flex -ml-3  w-auto mr-10">
                                                                    <ChevronLeft />{" "}
                                                                    <p className="text-lg text-c4 font-normal">
                                                                        {previous_text}
                                                                    </p>
                                                                </div>
                                                            </Link>
                                                        </SheetClose>
                                                        <div className="mt-7 ">
                                                            <Link
                                                                href={x.link}
                                                                target={getLinkTarget(x.linkTarget || "SELF")}>
                                                                <h2 className="font-medium text-xl hover:text-c2">
                                                                    {x.head}
                                                                </h2>
                                                            </Link>

                                                            {x.subcat[0].mode == "ICON" ? (
                                                                <div className="grid grid-cols-2  mt-8 items-center gap-3 w-full">
                                                                    {x.subcat.map(
                                                                        (x_sub: ShoppingCatagory, idx: number) => {
                                                                            //
                                                                            return (
                                                                                <SheetClose key={idx} asChild>
                                                                                    <Link
                                                                                        target={getLinkTarget(
                                                                                            x_sub.linkTarget || "SELF",
                                                                                        )}
                                                                                        href={x_sub.link}
                                                                                        onClick={() => {
                                                                                            setCloseAll(true);
                                                                                        }}>
                                                                                        <div className="flex flex-col w-full h-20  justify-center items-center aspect-square">
                                                                                            <Image
                                                                                                src={x_sub.icon}
                                                                                                alt={"image"}
                                                                                                width={130}
                                                                                                height={80}
                                                                                                unoptimized></Image>

                                                                                            <p className="text-c4 font-light text-sm mt-1">
                                                                                                {x_sub.name}
                                                                                            </p>
                                                                                        </div>
                                                                                    </Link>
                                                                                </SheetClose>
                                                                            );
                                                                        },
                                                                    )}
                                                                </div>
                                                            ) : (
                                                                <div className="flex flex-col mt-8 gap-[14px]">
                                                                    {x.subcat.map(
                                                                        (x_sub: ShoppingCatagory, idx: number) => {
                                                                            return (
                                                                                <div key={idx} className=" ">
                                                                                    <ShoppingTopBarSubmenuMobile
                                                                                        itm={x_sub}
                                                                                        previous_text={x.head}
                                                                                        bgcolor={
                                                                                            bgcolor
                                                                                        }></ShoppingTopBarSubmenuMobile>
                                                                                </div>
                                                                            );
                                                                        },
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </SheetContent>
                                            </Sheet>
                                        ) : (
                                            <SheetClose key={idx} asChild>
                                                <Link
                                                    target={getLinkTarget(x.linkTarget || "SELF")}
                                                    href={x.link}
                                                    onClick={() => {
                                                        setCloseAll(true);
                                                    }}>
                                                    <div className="flex flex-col w-full h-20  justify-center items-center aspect-square">
                                                        <Image
                                                            src={x.icon}
                                                            alt={"image"}
                                                            width={130}
                                                            height={80}
                                                            unoptimized></Image>

                                                        <p className="text-c4 font-light text-sm mt-1">{x.name}</p>
                                                    </div>
                                                </Link>
                                            </SheetClose>
                                        );
                                    })}
                                </div>
                            ) : (
                                <div className="flex flex-col mt-8 gap-[14px]">
                                    {itm.subcat.map((x: ShoppingCatagory, idx: number) => {
                                        return (
                                            <div key={idx} className=" ">
                                                <ShoppingTopBarSubmenuMobile
                                                    itm={x}
                                                    previous_text={itm.head}
                                                    bgcolor={bgcolor}></ShoppingTopBarSubmenuMobile>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </SheetContent>
            </Sheet>
        ) : (
            <SheetClose asChild>
                <Link
                    className="text-c4 hover:text-c2 text-xl font-light hover:font-light"
                    href={itm.link}
                    target={getLinkTarget(itm.linkTarget || "SELF")}
                    onClick={() => {
                        setCloseAll(true);
                    }}>
                    {itm.name}
                </Link>
            </SheetClose>
        );
    };

    return (
        <div className="overflow-scroll no-scrollbar ">
            <Sheet open={open} onOpenChange={setOpen}>
                <SheetOverlay className="bg-black/80"></SheetOverlay>
                <SheetTrigger className="align-middle">
                    <MenuIcon className="mx-[10px] w-[22px] h-[22px] justify-center"></MenuIcon>
                </SheetTrigger>
                <SheetContent className={"w-[320px] overflow-auto " + additionClassname[type]}>
                    <div className=" flex flex-col  mt-8">
                        {type === 0 ? <NavSearch clasName="mb-8" onEnter={() => setOpen(false)} /> : null}
                        {/* <Link href="https://easyapp.ulifespace.com/register">
                            <Button variant={"gradient2"} className="w-full text-lg py-2">
                                {t("navigation.subscription")}
                            </Button>
                        </Link> */}
                        <div className=" flex flex-col gap-y-[14px]">
                            {shopcats.map((itm: ShoppingCatagory, idx: number) => (
                                <div key={idx} className="">
                                    <ShoppingTopBarSubmenuMobile
                                        itm={itm}
                                        previous_text={t("navigation.subnav_all")}
                                        bgcolor={additionClassname[type]}
                                    />
                                </div>
                            ))}
                        </div>
                        <div className="flex flex-col justify-center mt-10  gap-y-[14px]">
                            {mainProfileMenu
                                .filter((m) => m.active)
                                .map((itm: ProfileMenu, idx: number) => (
                                    <div key={idx}>
                                        <ProfileMenuButton profile={itm} idxKey={idx} key={idx} login={login} />
                                    </div>
                                ))}
                        </div>

                        <SheetClose>
                            {type == 0 ? (
                                <Link
                                    href="/business"
                                    className={cn(
                                        buttonVariants({ variant: "gradient3" }),
                                        "w-full text-lg py-2 mt-10",
                                    )}>
                                    <div className="w-full flex justify-center">
                                        <p className="">{t("navigation.know_your_businese")}</p>
                                        <ChevronRight></ChevronRight>
                                    </div>
                                </Link>
                            ) : (
                                <Link
                                    href="/"
                                    className={cn(
                                        buttonVariants({ variant: "gradient3" }),
                                        "w-full text-lg py-2 mt-10",
                                    )}>
                                    <div className="w-full flex justify-center">
                                        <p className="">{t("navigation.shoping_online")}</p>
                                        <ChevronRight></ChevronRight>
                                    </div>
                                </Link>
                            )}
                        </SheetClose>

                        <div className="hidden mt-7 items-center gap-5">
                            <Button
                                className="flex"
                                variant={"transparant"}
                                onClick={() => {
                                    handleOnValueChanged("th");
                                }}>
                                <Image
                                    className="mr-2"
                                    src={"/assets/icons/thai_flag.svg"}
                                    alt={""}
                                    width={22}
                                    height={22}></Image>{" "}
                                <p className="text-lg font-light text-c4">{t("navigation.th")}</p>
                            </Button>

                            <div className=" w-[1px] h-[22px] bg-grayc4"></div>

                            <Button
                                className="flex"
                                variant={"transparant"}
                                onClick={() => {
                                    handleOnValueChanged("en");
                                }}>
                                <Image
                                    className="mr-2"
                                    src={"/assets/icons/eng_flag.svg"}
                                    alt={""}
                                    width={22}
                                    height={22}></Image>
                                <p className="text-lg font-light text-c4">{t("navigation.eng")}</p>
                            </Button>
                        </div>
                    </div>
                </SheetContent>
            </Sheet>
        </div>
    );
};
