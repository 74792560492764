"use client";

import { cn } from "@/lib/utils";
import Link from "next/link";
import Image from "next/image";
import { useEffect, useState } from "react";
import { getSession } from "next-auth/react";
import { getUser } from "@/actions";
import { useProfile } from "@/lib/providers/profile-provider";

const ButtonProfile = ({ className }: { className?: string }) => {
    const [imgUrl, setImgUrl] = useState<string>("/assets/icons/profile.svg");
    const [href, setHref] = useState<string>("");
    const { triggerUpdateProfile } = useProfile();

    useEffect(() => {
        const _getImageProfile = async () => {
            const session = await getSession();
            if (session && session.user) {
                const user = await getUser();
                setImgUrl(user?.image || "/assets/icons/profile.svg");
                setHref("/profile");
            } else {
                setImgUrl("/assets/icons/profile.svg");
                setHref("/signin");
            }
        };
        _getImageProfile();
    }, [triggerUpdateProfile]);

    return (
        <div className={cn("mx-[10px] relative flex-shrink-0", className)}>
            {href ? (
                <Link href={href} className="text-gray">
                    {imgUrl === "/assets/icons/profile.svg" ? (
                        <Image src={imgUrl} width={22} height={22} alt="Profile Image" className="h-[22px] w-[22px]" />
                    ) : (
                        <Image
                            src={imgUrl}
                            width={22}
                            height={22}
                            alt="Profile Image"
                            className="h-[22px] w-[22px] rounded-full"
                            unoptimized
                        />
                    )}
                </Link>
            ) : (
                <Image src={imgUrl} width={22} height={22} alt="Profile Image" className="h-[22px] w-[22px]" />
            )}
        </div>
    );
};

export default ButtonProfile;
