"use client";

import React from "react";
import * as PopoverPrimitive from "@radix-ui/react-popover";

export const Popover = PopoverPrimitive.Root;
export const PopoverTrigger = PopoverPrimitive.Trigger;

export const PopoverContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    ({ children, ...props }, forwardedRef) => (
        <PopoverPrimitive.Portal>
            <PopoverPrimitive.Content sideOffset={5} {...props} ref={forwardedRef}>
                {children}
                <PopoverPrimitive.Arrow />
            </PopoverPrimitive.Content>
        </PopoverPrimitive.Portal>
    ),
);
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

export const PopoverClose = PopoverPrimitive.Close;
export const PopoverArrow = PopoverPrimitive.Arrow;
